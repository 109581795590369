<!-- Facturas - Expedientes de Miscelaneas -->

<template>
  <div class="misc_Fras_F">
    <base_F
      v-if="schema_fras"
      :schema="schema_fras"
      :header="header"
      :titulo="titulo"
      :tipo="tipo"
      :sGrid="sGrid"
      :Entorno="Entorno"
      :btn_cerrarForm="btn_cerrarForm"
      :dblclick="dblclick"
      :selectable="selectable"
      @onEvent="event_capture"
    >
      <!-- Scoped Slot Campos del Grid -->
      <template v-slot:fields_grid="{ Sitem, Sheader }">
        {{ get_fieldGrid(Sitem, Sheader) }}
      </template>
    </base_F>
  </div>
</template>

<script>
import { mixinFinder } from "@/mixins/mixinFinder.js";
import plugs from "@/common/general_plugs";
const base_F = () => plugs.groute("base_F.vue", "base");

export default {
  mixins: [mixinFinder],
  components: { base_F },
  props: {
    schema: { type: Object, default: null },
    id: { type: String, default: "" },
    accion: { type: String, default: "" },
    edicion: { type: Boolean, default: false },
    tipo: { type: String, default: "finder" },
    btra: { type: [Array, Object], default: undefined },
    header: { type: Boolean, default: true },
    btn_cerrarForm: { type: Boolean, default: false },
    dblclick: { type: String, default: "0" },
    selectable: { type: Boolean, default: true }
  },

  data() {
    return {
      // variables de configuración
      Entorno: undefined,

      // variables del componente
      finder: "miscF", // api particular
      titulo: "Facturas - Expedientes", // título a mostrar en la cabecera del Finder
      schema_fras: null, // schema

      sGrid: {
        headers: [],
        records: [],
        header_ini: [
          { text: "T", value: "tp", width: "5%" },

          { text: "Expediente", value: "control", width: "10%" },

          { text: "*", value: "tpo", width: "10%" },

          { text: "Factura", value: "fra", width: "15%" },

          { text: "Fecha", value: "fhf", width: "15%" },

          { text: "Importe", value: "imp", width: "30%" },

          { text: "Asiento", value: "asi", width: "15%" }
        ]
      }
    };
  },

  methods: {
    // gestor de eventos
    event_capture(evt) {
      console.log(
        "*** event_capture misc_Fras_F. Accion: " + evt.accion + " ***"
      );

      if (this.$isFunction(this[evt.accion])) this[evt.accion](evt);
      if (evt.accion === "cerrar_form")
        this.$emit("onEvent", { accion: "cerrar_fras" });
    },

    // obtengo el schema del Finder
    async ini() {
      // cargo schema finder
      this.schema_fras = await this.$store.dispatch("get_schema", {
        tipo: this.tipo,
        name: this.finder,
        btra: this.btra
      });

      // obtengo facturas Grid
      await this.get_facturas();
    },

    // obtengo facturas Grid
    async get_facturas() {
      await this.get_records({ datos_filtro: { id: this.id } });
    },

    // devuelvo cada campo del grid
    get_fieldGrid(row, header) {
      switch (header.value) {
        case "fh":
          return this.sql2visual({ format: "fh" }, row.item.fh);

        default:
          return row.item[header.value];
      }
    }
  }
};
</script>
